$green: 		#3D9924;
$lightGreen:	#BAD36E;
$yellow:		#FFEF33;
$orange:		#F89610;
$pink:			#DB0043;
$lightGrey:		#E2E2E2;
$darkGrey:		#707070;
$key:			#646464;

$ease:			cubic-bezier(0.215, 0.610, 0.355, 1.000);
$easeBackOut:	cubic-bezier(0.175, 0.885, 0.32, 1.275);
$elastic:		cubic-bezier(0.150, 1.650, 0.330, 0.775);