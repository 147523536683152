.save-score {
	width: 1080px;
	height: 1791px;
	background: url(../img/save-score.png);
	position: absolute;
	left: 0px;
	top: 129px;
	z-index: 12;

	label {
		position: absolute;
		left: 129px;
		top: 301px;
		width: 822px;
		text-align: center;
		font-size: 132px;
		line-height: 147px;
		color: #fff;
	}

	&__name {
		position: absolute;
		left: 111px;
		top: 467px;
		width: 875px;
		height: 168px;
		border-radius: 20px;
		background: #E2E2E2;
		border: 1px solid #707070;
		text-align: center;
		font-size: 132px;
		line-height: 168px;
		color: #0F0F11;
	}

	.key {
		width: 130px;
		height: 122px;
		border-radius: 10px;
		background: #646464;
		color: #fff;
		font-size: 81px;
		line-height: 122px;
		text-align: center;
		position: absolute;
		user-select: none;
		text-transform: uppercase;
	}

	.a, .b, .c, .d, .delete {
		top: 687px;
	}

	.e, .f, .g, .h, .i {
		top: 829px;
	}

	.j, .k, .l, .m, .n, .o {
		top: 829+142px;
	}

	.p, .q, .r, .s, .t {
		top: 1113px;
	}

	.u, .v, .w, .x, .y, .z {
		top: 1255px;
	}

	.a, .j, .u {
		left: 88px;
	}

	.e, .p {
		left: 135px;
	}

	.b, .k, .v {
		left: 241px;
	}

	.f, .q {
		left: 288px;
	}

	.c, .l, .w {
		left: 394px;
	}

	.g, .r {
		left: 441px;
	}

	.d, .m, .x {
		left: 547px;
	}

	.h, .s {
		left: 594px;
	}

	.delete, .n, .y {
		left: 702px;
	}

	.delete {
		width: 279px;
	}

	.i, .t {
		left: 747px;
	}

	.o, .z {
		left: 854px;
	}

	&__save {
		position: absolute;
		left: 176px;
		top: 1422px;
	}
}