.background-layer {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	background-size: 100% 100%;
	pointer-events: none;

	&--1 {
		background-image: url(../img/background-1.png);
		z-index: 12;
	}

	&--2 {
		background-image: url(../img/background-2.png);
		z-index: 10;
	}

	&--3 {
		background-image: url(../img/background-3.png);
		z-index: 8;
	}

	&--4 {
		background-image: url(../img/background-4.png);
		z-index: 6;
	}

	&--5 {
		background-image: url(../img/background-5.png);
		z-index: 4;
	}


	&--6 {
		background-image: url(../img/background-6.png);
		z-index: 2;
	}
}

.landscape {
	.background-layer {
		&--1 {
			background-image: url(../img/landscape/background-1.png);
		}
	
		&--2 {
			background-image: url(../img/landscape/background-2.png);
		}
	
		&--3 {
			background-image: url(../img/landscape/background-3.png);
		}
	
		&--4 {
			background-image: url(../img/landscape/background-4.png);
		}
	
		&--5 {
			background-image: url(../img/landscape/background-5.png);
		}
	
	
		&--6 {
			background-image: url(../img/landscape/background-6.png);
		}
	}
}