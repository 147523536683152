.leaderboard {
	width: 1080px;
	height: 1980px;
	background: url(../img/leaderboard.png);
	position: absolute;
	left: 0px;
	top: 0;
	z-index: 12;

	h2, h3 {
		position: absolute;
		color: #fff;
		left: 129px;
		width: 822px;
		text-align: center;
		margin: 0;
	}

	h2 {
		top: 230px;
		font-size: 132px;
		line-height: 147px;
	}

	h3 {
		top: 410px;
		font-size: 68px;
		line-height: 70px;
	}

	#fireworks, canvas {
		position: absolute;
		left: 0px;
		top: 0px;
		width: 1080px;
		height: 1920px;
	}

	video {
		opacity: 0;
	}

	&__entries {
		position: absolute;
		left: 112px;
		top: 129+470px;
		width: 866px;
	}

	&__entry {
		background: #646464;
		border-radius: 20px;
		//width: 100%;
		height: 80px;
		color: #fff;
		font-size: 70px;
		line-height: 80px;
		padding: 0 34px;
		margin-bottom: 10px;

		&--mine {
			background: $green;
		}

		span {
			float: right;
		}
	}


	&__play-again {
		position: absolute;
		left: 176px;
		top: 129+1422px;
	}
}