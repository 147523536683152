.well-done {
	z-index: 7;
	position: absolute;
	left: 52px;
	top: 552px;
	width: 962px;
	height: 848px;
	background: url(../img/board.png);
	background-size: 100% 100%;
	opacity: 0;
	transition: top 0.3s, opacity 0.3s $ease;

	&--in {
		top: 512px;
		opacity: 1;
	}

	&--out {
		top: 552px;
		opacity: 0;
		transition-delay: 0.1s;
	}

	h2, h3, p {
		position: absolute;
		color: #fff;
		text-align: center;
		margin: 0;
		font-weight: normal;
	}

	h2 {		
		font-size: 200px;
		line-height: 223px;
		left: 132px;
		top: 53px;
		width: 712px;
	}

	h3 {		
		font-size: 120px;
		line-height: 134px;
		left: 132px;
		top: 247px;
		width: 712px;

		span {
			color: $lightGreen;
		}
	}

	p {
		left: 132px;
		top: 402px;
		width: 712px;
		font-size: 68px;
		line-height: 70px;
	}
}

.save {
	left: 176px;
	top: 1249+40px;
	opacity: 0;
	z-index: 12;
	position: absolute;
	transition: opacity 0.3s, top 0.3s;

	&--in {
		opacity: 1;
		top: 1249px;
		transition-delay: 0.1s;
	}

	&--out {
		opacity: 0;
		top: 1249+40px;
		transition-delay: 0.1s;
	}
}

.play-again {
	//left: 176px;
	left: 50%;
	margin-left: -369px;
	top: 1551+40px;
	opacity: 0;
	z-index: 12;
	position: absolute;
	transition: opacity 0.3s, top 0.3s;

	&--in {
		opacity: 1;
		top: 1551px;
		transition-delay: 0.25s;
	}

	&--out {
		opacity: 0;
		top: 1551+40px;
		transition-delay: 0.1s;
	}
}

.landscape {
	.well-done {
		z-index: 8;
		left: 500px;
		top: 116px;
	}

	.play-again {
		top: 800+40px;
		&--in {
			top: 800px;
		}

		&--out {
			top: 800+40px;
		}
	}
}