.score-board {
	position: absolute;
	width: 745px;
	height: 499px;
	left: 144px;
	top: 313px;
	background-image: url(../img/scoreboard.png);
	z-index: 2;
	transition: top 0.35s;

	&__label, &__score {
		font-size: 148px;
		position: absolute;
		top: 58px;
	}

	&__label {
		color: #fff;
		left: 117px;
	}

	&__score {
		left: 454px;
		color: $lightGreen;
	}

	&--out {
		top: 680px;
	}
}

.landscape {
	.score-board {
		left: 587px;
		top: 113px;

		&--out {
			top: 420px;
		}
	}
}