@font-face {
	font-family: 'CrocodileFeet';
	src: url('../fonts/CrocodileFeet-Regular.woff') format('woff');
	font-style: normal;
	font-weight: normal;
	text-rendering: optimizeLegibility;
}
body {
	font-family: 'CrocodileFeet', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, p {
	margin: 0;
	font-weight: normal;
}