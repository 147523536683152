.timer {
	width: 239px;
	height: 239px;
	border-radius: 120px;
	background: #fff;
	position: absolute;
	top: 17px;
	left: 807px;
	z-index: 2;
	transition: opacity 0.35s, top 0.35s;

	&--out {
		top: 0;
		opacity: 0;
	}

	&__time {
		font-size: 140px;
		line-height: 140px;
		position: absolute;
		left: 23px;
		top: 48px;
		width: 195px;
		text-align: center;
		color: $green;
	}

	&__progress {
		position: absolute;
		left: 16px;
		top: 16px;
		transform: rotate(-90deg);
	}

	&__value, &__back {
		fill: none;
		stroke-linecap: round;
		transition: all 1s linear;
	}

	&__value {
		stroke: $green;
	}

	&__back {
		stroke: #F4F4F4;
	}
}

.landscape {
	.timer {
		left: 1647px;
	}
}