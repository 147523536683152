.cloud {
	position: absolute;
	background-size: cover;
	z-index: 1;

	&--1 {
		left: 514px;
		top: 355px;
		width: 199px;
		height: 89px;
		background-image: url(../img/cloud1.png);
	}

	&--2 {
		left: 0px;
		top: 266px;
		width: 157px;
		height: 68px;
		background-image: url(../img/cloud2.png);
	}

	&--3 {
		left: 690px;
		top: 89px;
		width: 186px;
		height: 83px;
		background-image: url(../img/cloud3.png);
	}

	.landscape & {
		&--1 {
			left: 1360px;
			top: 255px;
		}

		&--2 {
			left: 390px;
			top: 300px;
		}

		&--3 {
			left: 1490px;
		}
	}
}