.how-to-play {
	z-index: 7;
	position: absolute;
	left: 0px;
	top: 40px;
	width: 100%;
	height: 100%;
	background: url(../img/chalkboard.png) 52px 512px no-repeat;
	background-size: 962px 996px;
	opacity: 0;
	transition: top 0.3s, opacity 0.3s $ease;

	&--in {
		top: 0;
		opacity: 1;
	}

	&--out {
		top: 40px;
		opacity: 0;
		transition-delay: 0.1s;
	}

	h2, p {
		position: absolute;
		color: #fff;
		text-align: center;
		margin: 0;
		font-weight: normal;
	}

	h2 {		
		font-size: 182px;
		line-height: 182px;
		left: 76px;
		top: 591px;
		width: 914px;
	}

	p {
		font-size: 80px;
		left: 193px;
		width: 712px;
		line-height: 70px;
	}

	p:nth-child(2) {
		top: 816px;
	}

	p:nth-child(3) {
		top: 1004px;
	}

	&__start {
		position: absolute;
		//left: 176px;
		left: 50%;
		margin-left: -369px;
		top: 1551+40px;
		z-index: 12;
		opacity: 0;
		transition: top 0.3s, opacity 0.3s $ease;
		transition-delay: 0.15s;

		&--in {
			top: 1551px;
			opacity: 1;
		}

		&--out {
			transition-delay: 0s;
			opacity: 0;
			top: 1551+40px;
		}
	}

	h2.how-to-play__get-ready {
		position: absolute;
		font-size: 168px;
		line-height: 168px;
		left: 176px;
		top: 650px;
		width: 712px;
		transform: rotate(354deg);
		text-align: center;
	}

	&__count-down {
		position: absolute;
		left: 178px;
		top: 800px;
		width: 712px;
		font-size: 400px;
		line-height: 400px;
		color: $yellow;
		text-align: center;

		&--go {
			color: $lightGreen;
		}
	}
}

.landscape {
	.how-to-play {
		z-index: 8;
		background-position: center center;

		h2 {		
			left: 500px;
			top: 110px;

		}

		p {
			left: 600px;
		}
	
		p:nth-child(2) {
			top: 340px;
		}
	
		p:nth-child(3) {
			top: 340+188px;
		}

		&__start {
			top: 800+40px;
			&--in {
				top: 800px;
			}
	
			&--out {
				top: 800+40px;
			}
		}

		h2.how-to-play__get-ready {
			left: 600px;
			top: 150px;
		}
	
		&__count-down {
			left: 600px;
			top: 320px;
		}
	}
}