#app-root {
	position: fixed;
	left: 0;
	top: 0;
	display: flex;
	width: 100vw;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100); // ios
	overflow: hidden;
	align-items: center;
	align-content: center;
	justify-items: center;
	justify-content: center;
	background: url(../img/background.png);
	background-size: 250px 250px;
}

.position {
	width: 100vw;
	height: 177.77777777777777vw;
	//height: calc(-webkit-fill-available * 1.7777777777777777);

	@media (min-aspect-ratio: 9/16) {
		width: 56.25vh;
		width: calc(var(--vh, 1vh) * 56.25); // ios
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100); // ios
	}

	&.landscape {
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100); // ios
		width: 177.77777777777777vh;
		width: calc(var(--vh, 1vh) * 177.77777777777777); // ios

		@media (max-aspect-ratio: 16/9) {
			width: 100vw;
			height: 56.25vw;
		}
	}
}

.container {
	position: relative;
	background: #35ccff;
	overflow: hidden;
	width: 1080px;
	height: 1920px;
	transform-origin: 0 0;
	margin: 0 auto;

	&.landscape {
		width: 1920px;
		height: 1080px;
	}
}

.logo {
	position: absolute;
	left: 26px;
	top: 0;
	z-index: 2;
	width: 396px;
	height: 275px;
	background: url(../img/logo-small.svg) left top no-repeat;
	background-size: contain;

	.landscape & {
		left: 70px;
		top: 20px;
	}
}

.button {
	width: 738px;
	height: 242px;
	border-radius: 121px;
	font-size: 160px;
	border: none;
	padding: 0;

	&:focus, &:active {
		outline: none;
	}

	&--white {
		background: #fff;
		color: $green;
		box-shadow: 0 6px 30px 0 $green;

		&:active {
			background: $green;
			color: #fff;
			box-shadow: none;
		}
	}

	&--orange {
		background: $orange;
		color: #fff;
		box-shadow: 0 6px 30px 0 #433100;

		&:active {
			background: $pink;
			color: #fff;
			box-shadow: none;
		}
	}
}

.landscape {
	.button {
		transform: scale(0.7);
		transform-origin: center center;
	}
}