.pet {
	position: absolute;
	width: 290px;
	height: 425px;
	background: url(../img/sprite-sheet.png);
	background-size: 5800px 425px;
	transform: translateY(425px);
	transition: transform 0.5s;

	.landscape & {
		width: 290px * 0.85;
		height: 425px * 0.85;
		background: url(../img/sprite-sheet.png);
		background-size: auto 361.25px;
	}

	&--brown-rabbit {
		background-position: 0px 0;
	
		&.pet--1 { background-position: -290px 0; }
		&.pet--2 { background-position: -580px 0; }
		&.pet--3 { background-position: -870px 0; }
		&.pet--4 { background-position: -1160px 0; }

		.landscape & {
			&.pet--1 { background-position: -290px * 0.85 0; }
			&.pet--2 { background-position: -580px * 0.85 0; }
			&.pet--3 { background-position: -870px * 0.85 0; }
			&.pet--4 { background-position: -1160px * 0.85 0; }
		}
	}

	&--grey-rabbit {
		background-position: -4350px 0;
		
		&.pet--1 { background-position: -4640px 0; }
		&.pet--2 { background-position: -4930px 0; }
		&.pet--3 { background-position: -5220px 0; }
		&.pet--4 { background-position: -5510px 0; }

		.landscape & {
			background-position: -4350px * 0.85 0;
			&.pet--1 { background-position: -4640px * 0.85 0; }
			&.pet--2 { background-position: -4930px * 0.85 0; }
			&.pet--3 { background-position: -5220px * 0.85 0; }
			&.pet--4 { background-position: -5510px * 0.85 0; }
		}
	}

	&--cat {
		background-position: -1450px 0;
		
		&.pet--1 { background-position: -1740px 0; }
		&.pet--2 { background-position: -2030px 0; }
		&.pet--3 { background-position: -2320px 0; }
		&.pet--4 { background-position: -2610px 0; }
		
		.landscape & {
			background-position: -1450px * 0.85 0;
			&.pet--1 { background-position: -1740px * 0.85 0; }
			&.pet--2 { background-position: -2030px * 0.85 0; }
			&.pet--3 { background-position: -2320px * 0.85 0; }
			&.pet--4 { background-position: -2610px * 0.85 0; }
		}
	}

	&--dog {
		background-position: -2900px 0;
		
		&.pet--1 { background-position: -3190px 0; }
		&.pet--2 { background-position: -3480px 0; }
		&.pet--3 { background-position: -3770px 0; }
		&.pet--4 { background-position: -4060px 0; }
		
		.landscape & {
			background-position: -2900px * 0.85 0;
			&.pet--1 { background-position: -3190px * 0.85 0; }
			&.pet--2 { background-position: -3480px * 0.85 0; }
			&.pet--3 { background-position: -3770px * 0.85 0; }
			&.pet--4 { background-position: -4060px * 0.85 0; }
		}
	}

	&--in {
		transform: translateY(0);
	}

	&--left { left: 55px; }
	&--center { left: 395px; }
	&--right { left: 735px; }

	&--top { top: 645px; z-index: 5; }
	&--middle { top: 980px; z-index: 7; }
	&--bottom { top: 1350px; z-index: 9; }

	.landscape & {
		&--top { top: 343px; z-index: 5; }
		&--middle { top: 521px; z-index: 7; }
		&--bottom { top: 719px; z-index: 9; }

		&--top.pet--left {
			left: 350px;
		}
		&--top.pet--center {
			left: 815px;
		}
		&--top.pet--right {
			left: 1400px;
		}
		&--middle.pet--left {
			left: 250px;
		}
		&--middle.pet--center {
			left: 715px;
		}
		&--middle.pet--right {
			left: 1200px;
		}
		&--bottom.pet--left {
			left: 450px;
		}
		&--bottom.pet--center {
			left: 915px;
		}
		&--bottom.pet--right {
			left: 1300px;
		}
	}

	svg {
		position: absolute;
		left: 0;
		top: 0;

		transform: scale(0);

		path {
			fill: #fff;
		}
	}

	&--hit {
		svg {
			transform: scale(1);
			transition: transform 0.3s $elastic;
		}
	}

	&--dog {
		svg {
			left: 0px;
			top: 60px;
		}
	}

	&--cat {
		svg {
			left: 20px;
			top: 10px;
		}
	}

	&--brown-rabbit {
		svg {
			left: -20px;
			top: 30px;
		}
	}
}