@for $i from 0 through 21 {
	$margin: 5px * $i;
	$label: 5 * $i;

	.margin-top-#{$label} {
		margin-top: $margin;
	}
}

@for $i from 0 through 21 {
	$margin: 5px * $i;
	$label: 5 * $i;

	.margin-bottom-#{$label} {
		margin-bottom: $margin;
	}
}

@for $i from 0 through 21 {
	$margin: 5px * $i;
	$label: 5 * $i;

	.margin-right-#{$label} {
		margin-right: $margin;
	}
}

@for $i from 0 through 21 {
	$margin: 5px * $i;
	$label: 5 * $i;

	.margin-left-#{$label} {
		margin-left: $margin;
	}
}

.text-left { text-align: left; }
.text-center { text-align: center; }
.text-right { text-align: right; }

.inline { display: inline-block; }

.pull-right {
	float: right;
}

.clearFix {
	&:after {
		content: "";
		display: block;
		clear: both;
	}
}