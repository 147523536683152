.intro {
	position: relative;
	z-index: 14;
	width: 100%;
	height: 100%;

	&__video {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

		&--arrive {
			z-index: 2;
		}

		&--jump {
			z-index: 1;
		}
	}

	&--out &__video {
		opacity: 0;
		transition: opacity 1s;
	}

	&__start {
		position: absolute;
		//left: 176 / 1080 * 100%;
		left: 50%;
		margin-left: -369px;
		top: 1551 / 1920 * 100%;
		z-index: 3;
	}

	&--out &__start {
		opacity: 0;
		transition: opacity 1s;
	}
}

.landscape {
	.intro {
		&__start {
			top: 800px;
		}
	}
}